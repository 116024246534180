<template>
  <modal
    name="llmFeedbackModal"
    role="dialog"
    aria-label="llmFeedbackModal"
    class="modal-fullScreen"
    width="100%"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @opened="opened"
  >
    <FocusLoop :is-visible="isOpen">
      <div
        class="
          modal-content modal-final-content modal-dialogic-content
          final-feedback-modals
        "
      >
        <button
          type="button"
          class="modal-close"
          aria-label="Close Modal"
          @click="$modal.hide('llmFeedbackModal')"
        >
          <em class="icon-cross"></em>
        </button>
        <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
          <div class="modal-body m-auto">
            <div class="modal-title">
              <div class="final-feedback-title">
                <h1>Conversation Assessment</h1>
              </div>
            </div>
            <div v-if="ifLearnerAssessments" class="container">
              <div v-for="(feedback, name, index) in feedbackData" class="row" :key="feedback[index]">
                <h2>{{ feedback.data.attributes.custom_label || feedback.data.attributes.skill_set_title }}</h2>
                <p class="feedback-result" v-if="feedback.data.attributes.show_pass_fail_labels">{{ feedback.data.attributes.assessment_result || feedback.data.attributes.hit_feed_req_item_label }}</p>
                <p v-if="feedback.data.attributes.show_points">{{ feedback.data.attributes.earned_points }}/{{ feedback.data.attributes.possible_points }}</p>
                <p v-html="feedback.data.attributes.llm_feedback"></p>
              </div>
            </div>
            <div v-else class="container">
              <div v-for="(feedback, name, index) in feedbackData" class="row" :key="feedback[index]">
                <h2 v-if="feedback.show_labels">{{ feedback.skill_label }}</h2>
                <h2 v-else>{{ feedback.skill_name }}</h2>
                <p class="feedback-result">{{ feedback.result }}</p>
                <p>{{ feedback.feedback }}</p>
              </div>
            </div>
            <div class="modal-btn pb-5">
              <button @click="$modal.hide('llmFeedbackModal')" class="btn secondary">
                Close
              </button>
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
    </FocusLoop>
  </modal>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: "llmfeedbackModal",
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      id: this.$route.params.id,
      isOpen: false,
      updatedReport: false,
      feedbackData: "",
      llmType: "",
      settings: {},
      ifLearnerAssessments: false,
    };
  },
  methods: {
    beforeOpen(event) {
      console.log(event);
      if (event.params.feedback.learner_assessments.length > 0) {
        this.feedbackData = event.params.feedback.learner_assessments;
        this.ifLearnerAssessments = true;
      } else {
        this.feedbackData = event.params.feedback.assessment_results;
      }
      this.llmType = event.params.llm_type;
      this.isOpen = true;
    },
    opened() {
      let finalFeedbackModal = document.querySelector(".final-feedback-modals");
      finalFeedbackModal.focus();
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  width: 100%;
  text-align: right;
}
h1 {
  font-family: "muliblack";
  font-size: 40px;
  line-height: 100%;
  margin: 30px 0;
}
h2 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin: 50px 0 10px;
}
h3 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin-bottom: 1.4em;
}
h4 {
  font-family: "muliregular";
  font-size: 16px;
  margin: 0 0 5px;
}
h5 {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
.row {
  padding: 40px 0;
  border-bottom: #eaeaea 1px solid;
}
.row:last-child {
  border-bottom: 0;
}
.dialogic-final-feedback-item {
  margin-bottom: 3em;
  display: block;
  float: left;
  width: 100%;

  .item {
    margin: 10px 0 0;
  }
  .feedback-item-title {
    font-size: 16px;
    font-family: "muliextrabold";
    margin: 14px 0 0;
    color: #000000;
  }
}
.item {
  margin: 20px 0 0;
  float: left;
  width: 100%;
}
.item-image {
  float: left;
  width: 78px;
  height: 78px;
  margin: 0 25px 0 0;
}
.item-image img {
  max-width: 100%;
}
.item-content {
  float: left;
  margin: 3px 0 0;
}
.label {
  font-weight: bold;
  font-family: "muliblack";
  font-size: 13px;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  color: black;
}
.grading {
  width: 30%;
  text-align: right;
  justify-content: flex-end;
}
.grading-p {
  font-size: 32px;
  margin-bottom: 16px;
}
.grade-score-head {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.grade-score-head h3 {
  float: left;
}
.grade-score-head p {
  float: right;
  font-family: "mulibold";
}
.item-score {
  background-color: #3dbc9e;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding-top: 24px;
}
.item-score {
  h3 {
    font-family: "mulisemibold";
    font-size: 20px;
    line-height: 100%;
    color: #fff;
    margin: 12px 0 0;
  }
}
.grade-score {
  font-family: "mulibold" !important;
  float: right;
  margin-top: 5px;
}
.item-score-head {
  font-family: "mulibold" !important;
  margin-bottom: 5px;
}
.item-label {
  color: #3dbc9e;
  font-size: 14px;
  margin: 0;
}
.container {
  width: 100%;
  margin-left: 0;
}
.final-feedback-title {
  font-size: 35px;
}

.details-hide {
  display: none;
}
.active-details {
  display: block;
}

.attempts-tabs {
  display: flex;
  padding-left: 0;
  .attempt-tab {
    border: 1px solid #ccc;
    padding: 1em;
    display: inline-flex;
    margin-right: 1em;
    cursor: pointer;
  }
  .active-reattempt-tab {
    background: #3dbc9e;
    color: white;
  }
}

.coding-concept {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}

.feedback-result {
  font-weight: bold;
  font-size: 20px;
}
</style>

